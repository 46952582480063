import { getField, updateField } from 'vuex-map-fields'

import { apolloClient } from '@/graphql/apollo'
import { searchQuery } from '@/graphql/queries'

const state = {
  text: '',
  tags: [],
  brand: '',
  type: '',
  results: [],
  resultsMessage: '',
  previous: '',
  current: '',
  isSearching: false,
  hasOverlay: false,
  busy: false,
  hasMorePages: true,
  page: 1,
  lastQuery: '/'
}

// getters
const getters = {
  getField,
  getSearchQuery: (state) => {
    return searchQuery(state.text, state.brand, state.tags, state.page)
  }
}

// actions
// this.$store.dispatch('search/actionName', payload)
const actions = {
  fetchSearchResults({ state, commit, getters }) {
    apolloClient
      .query({
        query: getters.getSearchQuery,
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        state.hasMorePages = data.originals.paginatorInfo.hasMorePages
        commit('setSearchResults', data.originals)
        commit('setSearching', false)
      })
      .catch((e) => {
        console.error(e)
        this.$notify({
          group: 'updates',
          title:
            'Error getting search results. Refreshing token... please wait.',
          text: JSON.stringify(e),
          type: 'error'
        })
        setTimeout(() => {
          location.reload()
        }, 2500)
      })
  },
  async fetchMoreSearch({ getters, state }) {
    apolloClient
      .query({
        query: getters.getSearchQuery,
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        state.hasMorePages = data.originals.paginatorInfo.hasMorePages
        state.results.data = [...state.results.data, ...data.originals.data]
        setTimeout(() => {
          state.busy = false
        }, 2500)
      })
      .catch((e) => {
        console.error(e)
        this.$notify({
          group: 'updates',
          title: 'Error getting more images.',
          text: JSON.stringify(e),
          type: 'error'
        })
      })
  },
  getOneSearchResult({ state, commit, dispatch }, imageData) {
    dispatch('edit/fetchImage', imageData.id, { root: true })
  }
}

// mutations
// this.$store.commit('search/mutationName', payload)
const mutations = {
  updateField,
  setSearchText(state, newText) {
    state.text = newText
  },
  setSearching(state, status) {
    state.isSearching = status
  },
  setSearchQuery(state, query) {
    state.text = query.text
    state.brand = query.brand
    state.tags = query.tags
    state.page = 1
  },
  setSearchResults(state, newResults) {
    state.results = newResults
    state.isSearching = false
  },
  addSearchResults(state, newResults) {
    state.results.data = [...state.results.data, ...newResults.data]
    setTimeout(() => {
      state.busy = false
    }, 2500)
  },
  toggleOverlay(state) {
    state.hasOverlay = !state.hasOverlay
  },
  clearSearch(state) {
    state.text = ''
    state.tags = []
    state.brand = ''
    state.page = 1
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
