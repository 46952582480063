<template>
  <div
    v-if="$auth.isAuthenticated"
    :class="{ 'overflow-hidden': $route.name === 'home' }"
  >
    <base-loading-overlay />
    <base-nav-bar />
    <div class="flex mt-16">
      <div class="flex w-full">
        <div class="flex-1 w-full px-2 pt-6 pb-64 bg-gray-100">
          <router-view />
        </div>
      </div>
    </div>
    <base-footer />
    <notifications group="updates" />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
