<template>
  <transition name="fade">
    <div
      v-if="isLoading"
      class="fixed top-0 left-0 z-50 flex flex-wrap content-center justify-center w-full h-screen bg-gray-700-trans-75"
    >
      <div class="w-1/5 p-2">
        <div
          v-if="isLoading"
          class="p-6 text-gray-700 bg-gray-300 rounded shadow-2xl"
        >
          <base-icon class="mx-auto my-3 spinning" width="30" height="30">
            <icon-hourglass />
          </base-icon>
          <p class="text-xl font-black text-center">{{ loadingMessage }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconHourglass from '@components/icons/icon-hourglass'
import { mapState } from 'vuex'
export default {
  name: 'BaseLoadingOverlay',
  components: {
    IconHourglass
  },
  computed: {
    ...mapState(['isLoading', 'loadingMessage'])
  }
}
</script>

<style scoped>
.bg-gray-700-trans-75 {
  background-color: rgba(0, 0, 0, 0.75);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
