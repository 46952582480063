import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from '../auth/authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home')
  },
  {
    path: '/add',
    name: 'add',
    component: () => import('@/views/add-image')
  },
  {
    path: '/edit',
    name: 'edit',
    component: () => import('@/views/edit-image'),
    props: true
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(authGuard)

export default router
