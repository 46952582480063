import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
const { createUploadLink } = require('apollo-upload-client')

const cache = new InMemoryCache()

export const apolloClient = new ApolloClient({
  link: createUploadLink({
    uri: `${window.app.url}/graphql`,
    headers: {
      authorization: localStorage.getItem('token')
        ? `Bearer ${localStorage.getItem('token')}`
        : ''
    }
  }),
  cache
})

// export const apolloClient = new ApolloClient({
//   // You should use an absolute URL here
//   link: createUploadLink(`${window.app.url}/graphql`),
//   cache: new InMemoryCache(),
//   request: (operation) => {
//     const token = localStorage.getItem('token')
//     operation.setContext({
//       headers: {
//         authorization: token ? `Bearer ${token}` : ''
//       }
//     })
//   }
// })

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

Vue.use(VueApollo)
