<template>
  <div class="tooltip">
    <button :class="classList" @click="copyShortCut">
      <span class="hover-icon" v-if="iconPosition === 'left'"
        ><base-icon class-list="inline-block" width="13" height="12"
          ><icon-copy /></base-icon
      ></span>
      <span class="hover-icon" v-if="extraInfo > 0">{{ extraInfo }}(w)</span>
      {{ title }}
      <span class="hover-icon" v-if="iconPosition === 'right'"
        ><base-icon class-list="inline-block" width="13" height="12"
          ><icon-copy /></base-icon
      ></span>
    </button>
  </div>
</template>

<script>
import IconCopy from '@components/icons/icon-copy'
export default {
  components: {
    IconCopy
  },
  props: {
    title: {
      type: String,
      default: 'button title'
    },
    classList: {
      type: String,
      default: 'px-2 pt-1 cursor-pointer'
    },
    stringtocopy: {
      type: String,
      default: 'No text selected to copy :('
    },
    iconPosition: {
      type: String,
      default: 'right'
    },
    extraInfo: {
      type: Number,
      default: 0
    }
  },
  methods: {
    copyShortCut() {
      let strCache = this.stringtocopy
      const el = document.createElement('textarea') // Create a <textarea> element
      el.value = strCache // Set its value to the string that you want copied
      el.setAttribute('readonly', '') // Make it readonly to be tamper-proof
      el.style.position = 'absolute'
      el.style.left = '-9999px' // Move outside the screen to make it invisible
      document.body.appendChild(el) // Append the <textarea> element to the HTML document
      const selected =
        document.getSelection().rangeCount > 0 // Check if there is any content selected previously
          ? document.getSelection().getRangeAt(0) // Store selection if found
          : false // Mark as false to know no selection existed before
      el.select() // Select the <textarea> content
      document.execCommand('copy') // Copy - only works as a result of a user action (e.g. click events)
      document.body.removeChild(el) // Remove the <textarea> element
      if (selected) {
        // If a selection existed before copying
        document.getSelection().removeAllRanges() // Unselect everything on the HTML document
        document.getSelection().addRange(selected) // Restore the original selection
      }
      this.$notify({
        group: 'updates',
        title: 'Copied to clipboard:',
        text: strCache.substr(0, 35) + '...',
        type: 'info'
      })
    }
  }
}
</script>

<style>
.tooltip {
  position: relative;
  display: block;
}

.tooltip .hover-icon {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .hover-icon {
  visibility: visible;
  opacity: 1;
}
</style>
