import { getInstance } from './index'
import dayjs from 'dayjs'
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

export const authGuard = (to, from, next) => {
  const authService = getInstance()

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      const now = dayjs()

      const tokenExp = localStorage.getItem('token_exp')
      const expDate = dayjs.unix(tokenExp)
      if (now.isAfter(expDate)) {
        setLocalStorageAuth()
          .then(() => {})
          .catch((err) => {
            console.error('Error setting auth local storage', err)
          })
      }
      return next()
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  const setLocalStorageAuth = async () => {
    const tokenDetails = await authService.getIdTokenClaims()
    const token = await authService.getTokenSilently()
    localStorage.setItem('token_exp', tokenDetails.exp)
    localStorage.setItem('token', token)
    localStorage.setItem(
      'token_expires_at',
      dayjs.unix(tokenDetails.exp).format('LLLL')
    )
    return {
      token,
      token_exp: tokenDetails.exp
    }
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn()
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn()
    }
  })
}
