import { getField, updateField } from 'vuex-map-fields'

const state = {
  title: '',
  altText: '',
  comment: '',
  tags: [],
  brand: '',
  type: '',
  results: [],
  resultsMessage: '',
  preview: null,
  isLoading: null,
  isDrive: false
}

// getters
const getters = {
  getField
}

// actions
// this.$store.dispatch('add/actionName', payload)
const actions = {}

// mutations
// this.$store.commit('add/mutationName', payload)
const mutations = {
  updateField,
  setIsDrive(state, status) {
    state.isDrive = status
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
