import { getField, updateField } from 'vuex-map-fields'
import { arrayAlphabetic } from '@/utils/alpha'

const state = {
  all: [{ name: 'loading...' }],
  loading: false,
  add: {
    isOpen: false
  }
}

// getters
const getters = {
  getField
}

// actions
// this.$store.dispatch('search/actionName', payload)
const actions = {}

// mutations
// this.$store.commit('search/mutationName', payload)
const mutations = {
  updateField,
  setBrands(state, allBrands) {
    state.all = arrayAlphabetic(allBrands)
  },
  pushNewBrand(state, brand) {
    state.all.push(brand)
    state.all = arrayAlphabetic(state.all)
  },
  setLoading(state, status) {
    state.loading = status
  },
  setIsOpen(state, status) {
    state.add.isOpen = status
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
