<template>
  <div
    class="w-1/6 h-screen pl-2 pr-4 font-light tracking-wide text-gray-800 bg-transparent"
  >
    <form @submit.prevent="submitSearch">
      <label
        for="text-search"
        class="mt-2 ml-1 text-sm font-light leading-5 text-gray-600"
        >search</label
      >
      <input
        id="text-search"
        v-model="text"
        type="text"
        name="text-search"
        class="w-full px-2 py-1 bg-white border border-gray-300 rounded"
      />
      <label
        for="brand"
        class="mt-2 ml-1 text-sm font-light leading-5 text-gray-600"
        >brand
        <span v-if="allBrands.length > 0" class="text-xs text-gray-500"
          >({{ allBrands.length }})</span
        ></label
      >
      <v-select
        v-if="allBrands.length > 0"
        id="brand"
        v-model="brand"
        label="name"
        :options="allBrands"
        transition=""
      />
      <p v-else class="p-2 text-xs text-gray-500 bg-white border rounded">
        loading brands...
      </p>
      <label
        for="tags"
        class="mt-2 ml-1 text-sm font-light leading-5 text-gray-600"
        >tags
        <span v-if="allTags.length > 0" class="text-xs text-gray-500"
          >({{ allTags.length }})</span
        ></label
      >
      <v-select
        v-if="allTags.length > 0"
        id="tags"
        v-model="tags"
        label="name"
        :options="allTags"
        multiple
        transition=""
      />
      <p v-else class="p-2 text-xs text-gray-500 bg-white border rounded">
        loading tags...
      </p>

      <button
        class="float-right px-4 py-1 mt-6 ml-3 font-light text-gray-500 transition-all duration-300 border border-gray-300 rounded hover:text-blue-500 hover:border-blue-400 hover:shadow"
        @click="submitSearch"
      >
        <base-icon class="inline-block mr-1 fill-current" width="16" height="16"
          ><icon-search />
        </base-icon>
        submit
      </button>
      <button
        class="float-right px-4 py-1 mt-6 font-light text-gray-500 transition-all duration-300 rounded hover:text-blue-500 hover:shadow"
        @click="clearSearch"
      >
        clear
      </button>
    </form>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapState } from 'vuex'
import { tagsByBrand, allTags } from '@/graphql/queries'
import IconSearch from '@components/icons/icon-search'

const { mapFields } = createHelpers({
  getterType: 'search/getField',
  mutationType: 'search/updateField'
})

export default {
  components: {
    IconSearch
  },
  data() {
    return {
      loading: false,
      text: '',
      brand: '',
      tags: ''
    }
  },
  computed: {
    ...mapState({
      allBrands: (state) => state.brands.all,
      allTags: (state) => state.tags.all
    }),
    queryObject() {
      let obj = {}
      if (this.text) obj['text'] = this.text
      if (this.brand) obj['brand'] = JSON.stringify(this.brand)
      if (this.tags.length > 0) {
        obj['tags'] = JSON.stringify(this.tags)
      }
      return obj
    },
    queryString() {
      return this.$route.fullPath
    }
  },
  watch: {
    brand: function(val) {
      // use this watcher to fire off a tagsByBrandQuery if needed
      if (val === null || val === '') {
        this.allTagsQuery()
      } else {
        this.tagsByBrandQuery(val)
      }
    },
    queryString: function() {
      // watch the queryString to fire off a new search query
      // instead of firing the query on specific methods, we rely on the
      // query string changing to indicate when a new search needs to happen
      this.setSearchFromUrl()
      this.$store.commit('search/setSearching', true)
      this.$store.dispatch('search/fetchSearchResults')
    }
  },
  created() {
    this.setSearchFromUrl()
  },
  methods: {
    submitSearch() {
      // update the url and query string to the local search data
      this.$router
        .push({
          name: 'home',
          query: this.queryObject
        })
        .catch((err) => {})
    },
    clearSearch() {
      this.$store.commit('search/clearSearch')
      this.clearLocalSearch()
      this.submitSearch()
    },
    clearLocalSearch() {
      this.text = ''
      this.brand = ''
      this.tags = ''
    },
    setSearchFromUrl() {
      // first clear out local search so UI is cleared
      this.clearLocalSearch()

      // grad the route query params and check which ones are set
      const query = this.$route.query
      if (query.text) this.text = query.text
      if (query.tags) this.tags = JSON.parse(query.tags)

      // if the brand query exists get the tags for that brand
      if (query.brand) {
        this.brand = JSON.parse(query.brand)
        this.tagsByBrandQuery(this.brand)
      } else {
        // otherwise just make sure all brands and tags are available
        this.fetchBrandsAndTags()
      }

      // once the local search data is updated from the query params,
      // update the vuex equivalent search data
      this.updateVuexSearch()

      // take the fullPath and set vuex lastQuery
      // used for the nav bar if someone clicks the 'search' menu item
      // then the previous search results are seen
      this.$store.commit('setField', {
        module: { name: 'search' },
        field: { name: 'lastQuery', value: this.$route.fullPath }
      })
    },

    updateVuexSearch() {
      // set all 3 search items based on local search data
      this.$store.commit('search/setSearchQuery', {
        text: this.text,
        brand: this.brand,
        tags: this.tags
      })
    },

    tagsByBrandQuery(val) {
      // when we have a brand we need to only show tags that pertain to the brand

      // clear out tags so that loading state is shown in UI
      this.$store.commit('tags/setTags', [])

      // fire off apollo query using tagsByBrand query function
      this.$apollo
        .query({
          query: tagsByBrand(val.name)
        })
        .then(
          ({
            data: {
              brands: {
                data: [{ tags }]
              }
            }
          }) => {
            this.$store.commit('tags/setTags', tags)
          }
        )
        .catch((e) => {
          this.$notify({
            group: 'updates',
            title: 'Error getting tagsByBrandQuery',
            text: JSON.stringify(e),
            type: 'error'
          })
        })
    },
    allTagsQuery() {
      this.$store.commit('tags/setTags', [])
      this.$apollo
        .query({
          query: allTags
        })
        .then(
          ({
            data: {
              allTags: { data }
            }
          }) => {
            this.$store.commit('tags/setTags', data)
          }
        )
        .catch((e) => {
          this.$notify({
            group: 'updates',
            title: 'Error getting all tags',
            text: JSON.stringify(e),
            type: 'error'
          })
        })
    }
  }
}
</script>
