<template>
  <div>
    <portal-target name="dropdown" slim />
    <nav
      class="fixed top-0 z-40 flex flex-wrap items-center justify-between w-full p-3 px-8 bg-white shadow-md"
    >
      <div class="flex items-center flex-shrink-0 mr-6">
        <svg
          class="w-48 h-12 mr-4"
          viewBox="0 0 1012 253"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve"
          style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
        >
          <g transform="matrix(1,0,0,1,-74.3591,-144.347)">
            <g transform="matrix(2.19709,0,0,1,-356.614,-37.4821)">
              <g transform="matrix(0.226948,0,0,0.498625,163.871,163.113)">
                <path
                  d="M184.106,437.712C156.448,395.229 143.459,347.032 142.313,296.64C141.534,262.428 149.418,227.205 162.692,195.805C175.674,165.095 194.684,136.994 218.254,113.424C241.825,89.853 269.925,70.843 300.635,57.862C324.293,47.861 349.43,41.401 374.989,38.821C478.132,28.41 579.833,82.009 629.331,173.153C645.598,203.107 655.866,236.237 659.29,270.159C663.355,310.434 658.024,351.457 643.55,389.282C625.41,436.688 593.049,478.246 551.722,507.675C551.722,507.675 437.96,333.071 379.271,242.992C371.748,231.447 358.902,224.484 345.122,224.484C331.342,224.484 318.496,231.447 310.973,242.992C263.708,315.536 184.106,437.712 184.106,437.712ZM485.595,139.52C524.782,139.52 545.075,188.495 517.358,216.211C513.282,220.288 508.392,223.605 503.076,225.844C497.506,228.189 491.643,229.377 485.595,229.377C479.548,229.377 473.684,228.189 468.114,225.844C462.798,223.605 457.909,220.288 453.832,216.211C426.116,188.495 446.408,139.52 485.595,139.52Z"
                  style="fill:rgb(41,139,165);"
                />
              </g>
              <g transform="matrix(0.331046,0,0,0.727337,97.9641,151.486)">
                <path
                  d="M558.626,367.477C533.597,380.972 504.983,388.625 474.595,388.625C420.04,388.625 371.2,363.959 338.642,325.151L399.331,232.005C399.331,232.005 413.705,209.943 424.164,193.89C426.517,190.279 430.535,188.101 434.846,188.101C439.156,188.101 443.174,190.279 445.527,193.89C475.57,240 558.626,367.477 558.626,367.477Z"
                  style="fill:rgb(91,188,214);"
                />
              </g>
            </g>
            <g transform="matrix(2.19709,0,0,1,-356.614,-37.4821)">
              <g transform="matrix(3.31966,0,0,7.29358,-1616.75,-1775.04)">
                <text
                  x="587.576px"
                  y="289.798px"
                  style="font-family:'Montserrat-Regular', 'Montserrat', sans-serif;font-size:12px;"
                >
                  IMAGE LIBRARY
                </text>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="block lg:hidden">
        <button
          class="flex items-center px-3 py-2 text-blue-700 border border-blue-700 rounded hover:text-white hover:border-white"
        >
          <svg
            class="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        class="flex-grow block w-full ml-8 lg:flex lg:items-center lg:w-auto"
      >
        <div class="text-sm lg:flex-grow">
          <button
            @click="navigateToLastSearch()"
            class="block mt-4 mr-4 text-blue-500 border-b border-transparent lg:inline-block lg:mt-0 hover:text-blue-400 hover:border-blue-200"
          >
            search
          </button>
          <router-link
            to="/add"
            class="block mt-4 mr-4 text-blue-500 border-b border-transparent lg:inline-block lg:mt-0 hover:text-blue-400 hover:border-blue-200"
          >
            add image
          </router-link>
          <router-link
            to="/profile"
            class="block mt-4 mr-4 text-blue-500 border-b border-transparent lg:inline-block lg:mt-0 hover:text-blue-400 hover:border-blue-200"
          >
            profile
          </router-link>
        </div>
        <div>
          <BaseDropDown
            v-if="$auth.isAuthenticated"
            class="px-2 mt-1"
            placement="bottom-end"
            :links="links"
          >
            <div class="items-center inline cursor-pointer select-none group">
              <img
                class="inline-block w-10 h-10 bg-blue-100 border border-blue-100 rounded-full"
                :src="$auth.user.picture"
              />
              <svg
                class="inline-block w-5 h-5 group-hover:fill-blue-500 fill-grey-darkest focus:fill-indigo-700"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </BaseDropDown>
          <button
            v-else
            class="inline-block px-4 py-2 mt-4 text-sm leading-none text-blue-500 border border-blue-500 rounded hover:border-transparent hover:text-blue-400 hover:bg-white lg:mt-0"
            @click="login"
          >
            login
          </button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['search']),
    links() {
      return [{ text: 'profile', location: '/profile' }]
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    navigateToLastSearch() {
      this.$router.push(this.search.lastQuery)
    }
  }
}
</script>

<style>
.router-link-exact-active {
  @apply text-blue-400 border-blue-200;
}
</style>
