import { allBrandsAndTagsQuery } from '@/graphql/queries'
export default {
  methods: {
    fetchBrandsAndTags() {
      this.$apollo
        .query({
          query: allBrandsAndTagsQuery
        })
        .then(({ data }) => {
          this.$store.commit('brands/setBrands', data.allBrands)
          this.$store.commit('tags/setTags', data.allTags)
        })
        .catch((e) => {
          this.$notify({
            group: 'updates',
            title: 'Error loading brands and tag, Refreshing Token',
            text: JSON.stringify(e),
            type: 'info'
          })
          setTimeout(() => {
            location.reload()
          }, 1500)
        })
    }
  }
}
